import './src/assets/css/base.scss'
//import { Nav } from './src/js/nav'
const loadScript = (src, body) => {
  const script = document.createElement('script')
  if (src) {
    script.src = src
  }
  if (body) {
    script.innerText = body
  }
  script.async = true
  document.body.appendChild(script)
}

export function onRouteUpdate() {}

//export const registerServiceWorker = () => true
